<template>
    <div>
        <competition-header
          v-model="currentCompetition" :showBackButton="false">
        </competition-header>

        <b-container fluid>
          <b-button :to="{name: 'CompetitionEdit'}" style="width:100%; height:3rem;" ><b-icon icon="pencil"/> Configuration de la compétition</b-button>

          <collapse-panel v-for="round in currentCompetition.ROUNDS" :variant="round.isCompleted && round.isDebriefingCompletedOrSkipped ? 'success' : ( round.isStarted ? 'warning' : (!round.isReadyToBriefing ? 'danger' : ''))/*{'success': round.isCompleted, 'warning': round.isStarted && !round.isCompleted}*/" :visible="!round.isCompleted && round.isReadyToProcess" :key="round.round_number" :title="'Tour/Manche' + round.round_number" lazy>
            <round-launcher  :round="round">
              <template #briefing> </template>
            </round-launcher>
          </collapse-panel>

          <b-button :to="{name: 'CompetitionResults'}" style="width:100%; height:3rem;" ><b-icon icon="trophee"/> Résultats de la compétition & classement général</b-button>

        </b-container>
        <events-launcher-dashboard v-if="false"/>
    </div>
</template>

<script>
import CompetitionHeader from './components/CompetitionHeader.vue';
import EventsLauncherDashboard from '@/components/EventsLauncherDashboard.vue';
import CollapsePanel  from '@/components/Panels/CollapsePanel.vue';
import RoundLauncher from './components/Launchers/RoundLauncher.vue';

export default {
    components: { CompetitionHeader, EventsLauncherDashboard, CollapsePanel, RoundLauncher },
    data(){
      return {
        d_rounds: [],
      };
    },
    methods:{
      async refresh(){
      }
    },
    async mounted(){
      await this.refresh();
      this.$hideLoader();
    }

}
</script>
