<template>
  <div>

    <mobile-app-area>
    </mobile-app-area>
      <b-button :to="{name: 'CompetitionEdit'}" ><b-icon icon="pencil"/> Configuration de la compétition</b-button>
<!--
    <div v-for="round in rounds" :key="round.round_number">
      <b-button :variant="(round.isStarted || round.isBriefingStarted) ? 'warning' : 'secondary'" v-if="!round.isCompleted" @click="editRound(round)"><b-icon icon="pencil" /> Configuration de la manche {{ round.round_number }}</b-button>
      <b-button variant="success" v-if="round.isCompleted || true" @click="viewRoundResults(round)"><b-icon icon="trophy" /> Résultats de la manche {{ round.round_number }}</b-button>
    </div>

    <div v-for="category in categories" :key="category.id" class="row">
      <b-button
        class="cell"
        v-if="category.isCompleted"
        variant="success"
        @click="showCategoryResults(category)"
      >
        <div v-if="rounds.length > 1">Manche {{ category.ROUND.round_number }}</div>
        <div>{{ category.name }}</div>
        <div v-if="levels.length > 1">{{ category.LEVEL.name }}</div>
        <icon-category v-model="category.code" :level="category.level" />
        <div>{{ category.nbCompetitors }} {{ (category.nbCompetitors > 1) ? "compétiteurs" : "compétiteur"}}</div>
        <div class="action">RESULTATS</div>
    </b-button>
     <b-button
        v-for="event in categoryEvents(category.level, category.category)"
        :key="event.id"
        class="cell"
        :variant="event.isDisabled ? 'outline-dark' : (event.isCompleted ? 'success' : (event.isStarted ? 'warning' : ( event.hasErrors ? 'danger' : 'secondary')))"
        :disabled="event.isDisabled"
        @click="gotoEvent(event)"
      >
        <div class="config-button">
          <b-button
            style="float:right;"
            variant="primary"
            size="sm"
            class="config-button"
            v-if="(event.isStarted && !event.isCompleted)"
            @click="editEvent(event)">
            <b-icon icon="gear" />
          </b-button>
        </div>
        <div v-if="rounds.length > 1">Manche {{ event.ROUND.round_number }}</div>
        <div>{{ event.CATEGORY.name }}</div>
        <div v-if="levels.length > 1">{{ event.LEVEL.name }}</div>
        <icon-category v-model="event.category" :level="event.level" />
        <div>{{ event.name }}</div>
        <div>{{ event.nbCompetitors }} {{ (event.nbCompetitors > 1) ? "compétiteurs" : "compétiteur"}}</div>
        <div class="action" v-show="event.hasErrors">PREPARER</div>
        <div class="action" v-show="event.isDisabled && !event.hasErrors">EN ATTENTE...</div>
        <div class="action" v-show="!event.hasErrors && !event.isStarted">COMMENCER</div>
        <div class="action" v-show="event.isCompleted">RESULTATS</div>
        <div class="action" v-show="event.isStarted && !event.isCompleted">CONTINUER</div>
      </b-button>
    </div>-->
  </div>
</template>

<script>
import { RST_EVENT, RST_CATEGORY, RST_ROUND, SEASON_COMPETITION_TYPE_CATEGORY_LEVEL, RST_COMPETITOR } from "@/LocalDB";
import CompetitionsConfigurationHelper from "@/services/helpers/competitionsConfigurationHelper";
import RoundsConfigurationHelper from "@/services/helpers/roundsConfigurationHelper";
import EventsConfigurationHelper from "@/services/helpers/eventsConfigurationHelper";
//import IconCategory from './IconCategory.vue';
import MobileAppArea from './MobileAppArea.vue';

export default {
  components: { /*IconCategory,*/ MobileAppArea },
  data(){
    return {
      forceConfigEvent: false,
    }
  },
  computed:{
    rounds(){
      var retNumbers = this.events.map(evt => evt.round_number )
                  .filter((v, i, a) => a.indexOf(v) === i);
      return RST_ROUND.query().where("competition_id", this.currentCompetition_id).where(round => retNumbers.indexOf(round.round_number) >= 0).orderBy("round_number").get();
    },
    levels(){
      var ret = this.events.map(evt => evt.level)
                  .filter((value, index, self) => self.indexOf(value) === index);
      return ret;
    },
    categories(){
      var ret = this.events.map(evt => { return { level: evt.level, category: evt.category, id: evt.CATEGORY.id }})
                  .filter((value, index, self) => self.findIndex(item => item.level == value.level && item.category == value.category) == index);
      return ret;
    },
    events(){
      var ret = [];
      var hasCompetitionErrors = (CompetitionsConfigurationHelper.missingRequirementsBeforeRun(this.currentCompetition_id).length > 0);
      SEASON_COMPETITION_TYPE_CATEGORY_LEVEL.query()
        .where("competition_type", this.currentCompetition.competition_type)
        .where("year", this.currentCompetition.year)
        .orderBy("level")
        .orderBy("category")
        .get()
        .forEach(cat => {
          var nbCompetitors = RST_COMPETITOR.query()
                              .where("competition_id", this.currentCompetition_id)
                              .where("level", cat.level)
                              .where("category", cat.category)
                              .get()
                              .length;
          if(nbCompetitors > 0)
          {
            RST_ROUND.query().where("competition_id", this.currentCompetition_id).get().forEach(round => {
              var hasRoundErrors = hasCompetitionErrors;
              if(!hasRoundErrors)
                hasRoundErrors = (RoundsConfigurationHelper.missingRequirementsBeforeRun(round).length > 0);
              var rule = RST_CATEGORY.query()
                            .where("competition_id", this.currentCompetition_id)
                            .where("round_number", round.round_number)
                            .where("level", cat.level)
                            .where("category", cat.category)
                            .first()
                            .RULE;
              if(rule == null){
                ret.push({
                  level: cat.level,
                  LEVEL: cat.LEVEL,
                  category: cat.category,
                  CATEGORY: cat.CATEGORY,
                  round_number: round.round_number,
                  ROUND: round,
                  nbCompetitors: nbCompetitors,
                  event: '',
                  name: '?',
                  EVENT: null,
                  isStarted: false,
                  isCompleted: false,
                  isDisabled: false,
                  hasCompetitionLevelErrors: hasCompetitionErrors,
                  hasRoundLevelErrors: hasRoundErrors,
                  hasEventLevelErrors: hasRoundErrors,
                });
              } else {
                rule.EVENTS.forEach(evt => {
                  var event = RST_EVENT.query()
                                .where("competition_id", this.currentCompetition_id)
                                .where("round_number", round.round_number)
                                .where('level', cat.level)
                                .where('category', cat.category)
                                .where("event", evt.event)
                                .first();
                  if(round.round_number > 1)
                  {
                    var prevRoundCategory = RST_CATEGORY.query()
                                .where("competition_id", this.currentCompetition_id)
                                .where("round_number", round.round_number - 1)
                                .where('level', cat.level)
                                .where('category', cat.category)
                                .first();
                  }
                  var hasEventErrors = (hasRoundErrors || (event == null)) ? true : false;
                  if(!hasEventErrors)
                    hasEventErrors = (EventsConfigurationHelper.missingRequirementsBeforeRun(event).length > 0);

                  var toPush = {
                    level: cat.level,
                    LEVEL: cat.LEVEL,
                    category: cat.category,
                    CATEGORY: cat.CATEGORY,
                    round_number: round.round_number,
                    ROUND: round,
                    nbCompetitors: nbCompetitors,
                    event: evt.event,
                    name: evt.EVENT.name,
                    EVENT: evt.EVENT,
                    isStarted: (event == null) ? false : event.isStarted,
                    isCompleted: (event == null) ? false : event.isCompleted,
                   // isDisabled: false,
                    hasCompetitionLevelErrors : hasCompetitionErrors,
                    hasRoundLevelErrors: hasRoundErrors,
                    hasEventLevelErrors: hasEventErrors,
                    hasErrors: hasCompetitionErrors || hasRoundErrors || hasEventErrors,
                    isDisabled : (event == null && prevRoundCategory != null && !prevRoundCategory.isCompleted)
                  };
                  ret.push(toPush);
                })
              }
            });
          } else {
              console.log("TODO : Gestion des catégories sans compétiteurs à implémenter");
          }
        });

      return ret.sort((a,b)=> b-a);
    }
  },
  methods:{
    categoryEvents(level, category)
    {
      var ret = this.events.filter(evt => evt.level == level && evt.category == category).sort((a,b) => { return (a.round_number > b.round_number) ? -1 : (a.round_number < b.crouund_number ? 1 : 0); });
      return ret;
    },
    editRound(round){
      this.setCurrentRound_number(round.round_number);
      this.$router.push({ name: 'RoundPrepare'});
    },
    viewRoundResults(round)
    {
      this.setCurrentRound_number(round.round_number);
      this.$router.push({ name: 'RoundResults'}, { competition_id: round.competition_id, round_number: round.round_number});
    },
    setEvent(event){
      EventsConfigurationHelper.ensureEventExists(
          this.currentCompetition_id,
          event.round_number,
          event.level,
          event.category,
          event.event);

      this.setCurrentRound_number(event.round_number);
      this.setCurrentLevel_code(event.level);
      this.setCurrentCategory_code(event.category);
      this.setCurrentEvent_code(event.event);
    },
    showCategoryResults(category)
    {
      this.setCurrentRound_number(category.round_number);
      this.setCurrentLevel_code(category.level);
      this.setCurrentCategory_code(category.category);
      this.$router.push("RoundResults");
    },
    gotoEvent(event)
    {
      console.log("gotoEvent", event);
      if(!event.isStarted || this.forceConfigEvent)
        this.startEvent(event);
      else if(event.isCompleted)
        this.showEventResults(event);
      else
        this.continueEvent(event);
    },
    startEvent(event){
      this.setEvent(event);
      if(event.hasCompetitionLevelErrors){
        this.$bvModal.msgBoxOk("Il reste des erreurs de configuration au niveau de la compétition en général. Vous devez les Corriger avant de pouvoir continuer. Vous allez être redirigé !")
          .then(() => {
            this.$showLoader()
              .then(() => {
                this.$router.push({name:"CompetitionEdit"});
              });
          });
      } else if(event.hasRoundLevelErrors){
        this.$bvModal.msgBoxOk("Il reste des erreurs de configuration au niveau de la manche en général. Vous devez les corriger avant de pouvoir continuer.Vous allez être redirigé !")
          .then(() => {
            this.$showLoader()
              .then(() => {
                this.$router.push({name:'RoundPrepare'});
              });
          });
      } else {
        this.$showLoader()
          .then(() => {
            if(event.hasEventLevelErrors){
              this.$router.push({ name:'EventPrepare'});
            } else {
              this.$router.push({ name: 'EventPrepare'});
              //this.$router.push({ name: 'EventBriefing'});
            }
          });

      }
    },
    editEvent(event)
    {
      console.log("editEvent", event);
      this.forceConfigEvent = true;
    },
    continueEvent(event){
      this.$showLoader()
        .then(() => {
          this.setEvent(event);
          this.$router.push({ name: 'EventRun'});
        })
    },
    showEventResults(event){
      this.$showLoader()
        .then(() => {
          this.setEvent(event);
          this.$router.push({ name: 'EventResults'});
        })
    }
    /*levelCategories(level){
      var ret = RST_CATEGORY.query()
                  .where("competition_id", this.currentCompetition_id)
                  .where("level", level.level)
                  .where(item => item.COMPETITORS.length > 0)
                  .orderBy("name")
                  .get();
      return ret;
    },*/
    /*events(level, category, round){
      var ret = RST_EVENT.query()
                  .where("competition_id", this.currentCompetition_id)
                  .where("level", level.level)
                  .where("category", category.level)
                  .where(item => item.COMPETITORS.length > 0)
    }*/
    /*categories(){
      var ret = [];
      RST_CATEGORY.query()
        .where("competition_id", this.currentCompetition_id)
        .orderBy("level", "asc")
        .orderBy("category", "asc")
        .orderBy("round_number", "asc")
        .get()
        .forEach(item => {

        })
    },*/
  }
}
</script>

<style scoped>
  .row {
    display:flex;
    justify-content: center;
    align-items: center;
  }
  .cell {
    max-width: 200px;
    max-height: 200px;
    min-width: 200px;
    min-height: 200px;
  }
  div.config-button{
    margin: 1px;
    min-height: 16px;
  }
  .config-button{
    margin:0px;
  }
  .action{ font-size:1.2rem; font-weight: bolder; }
</style>


